<script>
import CardIconLabel from '../CardIconLabel'
import api from '@/command/api'
import { Col } from 'ant-design-vue'
export default {
  props: {
    cols: {
      type: Number,
      default: 12
    },
    title: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    },
    icons: {
      type: Array,
      default: () => []
    },
    styles: {
      type: Object,
      default: () => {}
    },
    pubBool: {
      type: Boolean,
      default: true
    },
    isAddBtn: {
      type: Boolean,
      default: true
    },
    carTitle: {
      type: String,
      default: '标签'
    }
  },
  data() {
    return {
      iconsArr: this.getData(this.icons)
    }
  },
  watch: {
    icons(newValue) {
      this.iconsArr = this.getData(newValue)
    }
  },
  methods: {
    getData(arr) {
      arr.forEach(e => {
        e.name = e.labelName
        e.newLabel = 0
        if (e.publicLabel == null) {
          e.publicLabel = '2'
        }
      })
      return arr
    },
    onSuccess({ data, setHidden }) {
      if (!data.labelName) {
        this.$message.warning('请输入标签名!')
        return
      }

      try {
        this.icons.forEach(e => {
          if (data.labelName == e.labelName) {
            this.$message.warning('标签已存在!')
            throw new Error('不可输入重复的数据！')
          }
        })
      } catch (error) {
        console.log(error)
        return
      }
      this.iconsArr.push({
        ...data,
        czState: 1,
        light: data.light || 0,
        name: data.labelName,
        newLabel: 1
      })
      setHidden()
    },
    onEdit({ data, setHidden } = {}, index) {
      this.iconsArr[index]['name'] = data.labelName
      this.$set(this.iconsArr[index], 'name', data.labelName)
      this.$set(this.iconsArr[index], 'czState', 1)
      setHidden()
    },
    onChose(item, index) {
      this.$set(this.iconsArr[index], 'light', this.iconsArr[index].light == 0 ? 1 : 0)
    },
    onDelete(item, index) {
      if (item.id) {
        api.command.delDetail.call(this, {
          url: `/base/farmLabelData/${item.id}`
        })
      }
      this.iconsArr.splice(index, 1)
    }
  },
  render() {
    return (
      <Col span={this.cols}>
        <CardIconLabel
          pubBool={this.pubBool}
          isAddBtn={this.isAddBtn}
          data={this.data}
          title={this.title}
          icons={this.iconsArr}
          onSuccess={this.onSuccess}
          onEdit={this.onEdit}
          onChose={this.onChose}
          onDelete={this.onDelete}
          styles={{ minHeight: '200px' }}
          carTitle={this.carTitle}
        />
      </Col>
    )
  }
}
</script>
