<script>
import FormView from '../FormView'
export default {
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Array,
      default: null
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    labelTypeCode: {
      type: String,
      default: ''
    }
  },
  methods: {
    getForm() {
      return [
        {
          name: '标签名称',
          type: 'input',
          key: 'labelName'
        },
        {
          name: '标签分类',
          type: 'iconClass',
          key: 'iconUrl',
          display: this.showIcon,
          labelTypeCode: this.labelTypeCode
        }
      ].filter(e => (e.display == undefined ? true : e.display))
    },
    sumbit() {
      this.$refs._form.sumbit()
    }
  },
  render() {
    return (
      <FormView
        data={this.data || this.getForm()}
        form={this.form}
        cols={24}
        ref="_form"
        onSuccess={data => this.$emit('success', data)}
      />
    )
  }
}
</script>
