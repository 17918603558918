<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 15
    },
    height: {
      type: Number,
      default: 15
    },
    color: {
      type: String,
      default: ''
    }
  },
  render() {
    const Com = require('@/assets/icons/' + this.icon + '.svg?inline')
    return <Com.default width={this.width} height={this.height} color={this.color} />
  }
}
</script>
